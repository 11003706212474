/* ======= base style ====== */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Cabin", sans-serif;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  body {
    background-color: #fff9f1;
    color: #4c4d4f;
    font-family: Cabin;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    overflow: hidden;
  }
  
  p {
    color: #4c4d4f;
    font-family: "Cabin", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 14px;
    border-radius: 6px;
    background: #d9d9d9;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px #d9d9d9;
    border-radius: 0px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #f1b34a;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #f1b34a;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #4c4d4f;
    font-weight: 600;
    font-family: "Cabin", sans-serif;
    font-style: normal;
  }
  
  h1 {
    font-size: 34px;
    font-weight: 400;
    line-height: 51px;
  }
  @media screen and (max-width: 767.98px) {
    h1 {
      font-size: 32px;
      line-height: 38px;
    }
  }
  
  h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 21px;
  }
  @media screen and (max-width: 767.98px) {
    h2 {
      font-size: 22px;
      line-height: 18px;
    }
  }
  
  h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
  @media screen and (max-width: 767.98px) {
    h3 {
      font-size: 10px;
      line-height: 18px;
    }
  }
  
  h5 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
  @media screen and (max-width: 767.98px) {
    h5 {
      font-size: 18px;
      line-height: 22px;
    }
  }
  
  h6 {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 767.98px) {
    h6 {
      font-size: 14px;
      line-height: 20px;
    }
  }
  
.sub-heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}

.btn-admin {
  padding: 10px 17px;
  align-items: center;
  border-radius: 14px;
  transition-duration: 0.4s;
}

.btn-admin-primary {
  color: #fff;
  border-color: #000;
  background-color: #000;
  border-radius: 14px;
}
.btn-admin-primary:hover, .btn-admin-primary:active {
  color: #000;
  border-color: #000;
  background-color: #fff;
}

.btn--admin-white {
  color: #4caf4f;
  border-color: #f5f7fa;
  background: #f5f7fa;
}
.btn--admin-white:hover {
  color: #fff;
  border-color: #4caf4f;
  background-color: #4caf4f;
}
.AdminLayout{
  overflow: hidden;
}
.AdminLayout .admin-header-main {
  width: 100%;
  background-color: #fff;
  height: 80px;

  top: 0;
}
.AdminLayout .admin-header-main .admin-header-items {
  height: 80px;
}
.AdminLayout .admin-header-main .admin-header-items .admin-mobile-btn-bg {
  padding: 15px 14px;
  border-radius: 14px;
  background: #f8f8f8;
}

.AdminLayout .admin-sidebar {
  min-height: 100vh;
  background-color: #fff !important;
  position: fixed;
  top: 80px;
  left: 0;
}
@media screen and (max-width: 991px) {
  .AdminLayout .admin-sidebar {
    width: 200px;
  }
}
@media screen and (min-width: 992px) {
  .AdminLayout .admin-sidebar {
    width: 280px;
  }
}

.AdminLayout .content-dashboard {
  background-color: #fff9f1;
  padding: 80px 0 0 0px;
}
@media screen and (max-width: 991px) {
  .AdminLayout .content-dashboard {
    padding: 80px 0 0 0px !important;
  }
}
@media screen and (min-width: 992px) {
  .AdminLayout .content-dashboard {
    padding: 100px 70px 0px 305px !important;
  }
}

.AdminLayout .content-dashboard .content-body {
  overflow-y: scroll;
  height: calc(100vh - 200px);
}
.AdminLayout .content-dashboard .content-body .card {
  border-radius: 12px;
  background: #fff;
  border: none;
 
}
.AdminLayout .content-dashboard .content-body .card .btn-delete {
  border-radius: 5px;
  width: 22px;
  height: 22px;
  background: white;
  box-shadow: 0px 4px 1px 0px rgba(0, 0, 0, 0.1);
  right: 10px;
  top: 12px;
  margin: auto;
  text-align: center;
}

.AdminLayout .content-dashboard .content-body .product-box {
  border-radius: 10px;
  border: 1px solid rgba(76, 77, 79, 0.2);
  background: #fff;
  padding: 18px;
}
.AdminLayout .content-dashboard .content-body .product-box .btn-admin {
  padding: 6px 35px;
  align-items: center;
  border-radius: 24px;
}
.AdminLayout .content-dashboard .content-body .product-box .product-img {
  border-radius: 5px;
}
.AdminLayout .content-dashboard .content-body .product-box .product-content h6 {
  margin-bottom: 0;
}
.AdminLayout .content-dashboard .content-body .product-box .product-content p.volume {
  color: #4c4d4f;
  font-size: 10px;
  font-weight: 400;
}
.AdminLayout .content-dashboard .content-body .product-box .product-content p.brand {
  color: rgba(76, 77, 79, 0.5);
  font-size: 10px;
  font-weight: 400;
}

.AdminLayout .content-dashboard .content-body .treatment-box {
  border-radius: 10px;
  border: 1px solid rgba(76, 77, 79, 0.2);
  background: #fff;
  padding: 22px 16px 22px 22px ;
}
.AdminLayout .content-dashboard .content-body .treatment-box .action-box {
  display: inline-flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #b2b2b2;
}
.AdminLayout .content-dashboard .content-body .treatment-box .content-box h6.title {
  color: #000;
  font-family: Cabin;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 4px;
}
.AdminLayout .content-dashboard .content-body .treatment-box .content-box p.sub-title {
  color: #4c4d4f;
  font-family: Cabin;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.AdminLayout .content-dashboard .content-body .treatment-box .content-box p.med-name {
  color: rgba(76, 77, 79, 0.5);
  font-family: Cabin;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.AdminLayout .content-dashboard .content-body .treatment-box .content-box p.med-price {
  color: #000;
  font-family: Cabin;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}
.AdminLayout .content-dashboard .content-body .treatment-box .btn {
  padding: 6px 35px;
  align-items: center;
  border-radius: 24px;
}

.custom-upload-file {
  border-radius: 25px;
  border: 1px dashed #a3a3a3;
  background: #eee;
  width: 100%;
  height: 180px;
}
.custom-upload-file input {
  z-index: 12;
}
.custom-upload-file .file-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  z-index: 1;
}
.treatment-box{
  border-radius: 10px;
  border: 1px solid rgba(76, 77, 79, 0.2);
  background: #fff;
  padding: 22px;
}
.admin-header-padding{
  padding-right: 0.5rem ;
  padding-left: 0.5rem;
}
@media screen and (max-width: 991px) {
  .admin-header-padding{
    padding-right: 5rem ;
    padding-left: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .admin-header-padding{
    padding-right: 7rem ;
    padding-left: 3rem;
  }
}
.gutter{
  --bs-gutter-x: 2.0rem;
}

.search-bar-container{
  background-color: #fff;
  border-radius: 15px;
}

.navbar-tab-heading-text{
  border: 1px solid #000 !important;
  padding: 30px !important;
  border-radius: 20px !important;
  text-align: start;
  color: #000 !important;
}
.accordians-faqs-generals{
  background-color: transparent;
  border: 0px solid transparent;
  border-bottom: 1px solid #000;
}
.accordians-faqs-generals button:active{
  background-color: transparent !important;
}
.accordion-button:not(.collapsed){
  background-color: transparent;
  box-shadow: none;
}
.accordion-button:focus {
  border-color: none;
  box-shadow: none;
}

.tabs-Faqs-data-container{
  background-color: #fff;
  border-radius: 15px;
  padding: 30px;
}
.navbar-tab-heading-text:active , 
.nav-tabs .nav-link.active{
  border: 1px solid #fff !important;
}

.personal-information-form-button{
  border-radius: 25px;
  padding: 10px 25px;
}
.personal-information-form-input{
  border: 1px solid transparent;
  border-bottom: 1px solid #000;
  padding: 10px 0px;
  border-radius: 0px;
}
.change-email-text{
  text-decoration: underline;
}