.admin-header {
  width: 100%;
  background-color: #fff;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 9999;
}
.admin-header .admin-mobile-btn-bg {
  padding: 15px 14px;
  border-radius: 14px;
  background: #f8f8f8;
}
.admin-header p {
  color: #4c4d4f;
  font-family: Cabin;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
